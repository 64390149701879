import React, { useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import FlightSearchBar from '../Flights/SearchFilters/FlightSearchBar';
import PaginationComponent from '../Flights/PaginationComponent';
import MemoizedFlightsTable from '../Flights/DisplayTables/DisplayTables'; // Ensure the import path is correct
import FlightsTable from '../Flights/DisplayTables/DisplayTables'; // Ensure the import path is correct
import ReactMarkdown from 'react-markdown';
import SortButton from '../Flights/DisplayTables/SortButton';

const parseMarkdown = (text) => {
    let parsedText = text;
  
    // Replace bold markdown (**) with <strong> tags
    parsedText = parsedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  
    // Replace italic markdown (*) with <em> tags
    parsedText = parsedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
  
    // Replace headers (###) with <h3> tags
    parsedText = parsedText.replace(/### (.*?)\n/g, '<h3>$1</h3>');
  
    // Replace list items (-) with <li> tags
    parsedText = parsedText.replace(/- (.*?)\n/g, '<li>$1</li>');
  
    // Wrap list items with <ul> tags
    parsedText = parsedText.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');
  
    return parsedText;
  };

  
const AIMessage = ({
  chatMessage,
  threadFlightData,
  setThreadFlightData,
  flightSearchParamsArr,
  newFlightParamsArr,
  setNewFlightParamsArr,
  runFlightSearch,
  setFavoriteFlightData,
}) => {
    const [idx, setIdx] = useState(0);
    const [sortBy, setSortBy] = useState('score');


    console.log('AIMessage.threadFlightData', threadFlightData);
    const filteredFlightData = threadFlightData.filter(item => item.ai_message_id === chatMessage.id);
    const paginatedFilteredFlightData = Object.values(filteredFlightData.reduce((acc, item) => {
            const key = item.search_id;
            if (!acc[key]) {
                acc[key] = [];
            }
                acc[key].push(item);
            return acc;
    }, {}));
    // console.log('AIMessage.filteredFlightData', filteredFlightData);
    // console.log('AIMessage.paginatedFilteredFlightData', paginatedFilteredFlightData);


    // console.log('AIMessage.flightSearchParamsArr', flightSearchParamsArr);
    // console.log('AIMessage.newFlightParamsArr', newFlightParamsArr);

    // const matchingNewFlightParams = useMemo(() => {
    //     return newFlightParamsArr.find(param => param.ai_message_id === chatMessage.id);
    // }, [newFlightParamsArr, chatMessage.id]);

    // const matchingFlightParams = useMemo(() => {
    //     return flightSearchParamsArr.find(param => param.ai_message_id === chatMessage.id);
    // }, [flightSearchParamsArr, chatMessage.id]);

    const matchingNewFlightParams = useMemo(() => {
        const found = newFlightParamsArr.find(param => param.ai_message_id === chatMessage.id);
        return found ? [found] : [];
    }, [newFlightParamsArr, chatMessage.id]);
      
    const matchingFlightParams = useMemo(() => {
        const found = flightSearchParamsArr.find(param => param.ai_message_id === chatMessage.id);
        return found ? [found] : [];
    }, [flightSearchParamsArr, chatMessage.id]);

    
    const paginatedParamsData = flightSearchParamsArr.filter(item => item.ai_message_id === chatMessage.id);
    const paginatedNewParamsData = newFlightParamsArr.filter(item => item.ai_message_id === chatMessage.id);

    // console.log('AIMessage.paginatedParamsData', paginatedParamsData);
    // console.log('AIMessage.paginatedNewParamsData', paginatedNewParamsData);
    // console.log('AIMessage.matchingFlightParams', matchingFlightParams);

    // const paginatedFilteredFlightParams = Object.values(matchingNewFlightParams.reduce((acc, item) => {
    //         const key = item.search_id;
    //         if (!acc[key]) {
    //             acc[key] = [];
    //         }
    //             acc[key].push(item);
    //         return acc;
    // }, {}));
    // const paginatedFilteredFlightParams = matchingNewFlightParams.length === 0
    //     ? []
    //     : Object.values(matchingNewFlightParams.reduce((acc, item) => {
    //         const key = item.search_id;
    //         if (!acc[key]) {
    //             acc[key] = [];
    //         }
    //         acc[key].push(item);
    //         return acc;
    //     }, {}));
    const N = paginatedParamsData.length;

    // const listofParams = Object.values(paginatedFilteredFlightData);

    // const FlightSearchComponent = ({ chatMessage }) => {

    //     const matchingNewFlightParams = useMemo(() => {
    //         return newFlightParamsArr.find(param => param.ai_message_id === chatMessage.id);
    //     }, [newFlightParamsArr, chatMessage.id]);

    //     const matchingFlightParams = useMemo(() => {
    //         return flightSearchParamsArr.find(param => param.ai_message_id === chatMessage.id);
    //     }, [flightSearchParamsArr, chatMessage.id]);

    //     return (
    //         <>
    //             {threadFlightData.length > 0 && matchingNewFlightParams && (
    //                 <FlightSearchBar flightParams={matchingFlightParams} newFlightParams={matchingNewFlightParams} setNewFlightParamsArr={setNewFlightParamsArr} />
    //             )}
    //         </>
    //     );
    // };

//   const filteredFlightData = threadFlightData.filter(item => item.ai_message_id === chatMessage.id);
//   const paginatedFilteredFlightData = filteredFlightData.reduce((acc, item) => {
//         const key = item.search_id;
//         if (!acc[key]) {
//             acc[key] = [];
//         }
//             acc[key].push(item);
//         return acc;
//   }, {});
//   const listofParams = Object.values(paginatedFilteredFlightData);
//   const N = listofParams.length;
  const ai_message_id = filteredFlightData.length > 0 ? filteredFlightData[0]['ai_message_id'] : null;

    console.log('sortBy',sortBy);

  return (
    <div className="chat-message">
      <p className="role">{chatMessage.role}</p>
      <div className="content">
        <div className='ai-message'>
            <ReactMarkdown>
                {chatMessage.content}
            </ReactMarkdown>
        </div>
        {/* <pre className='ai-message'>
            <ReactMarkdown>
                {chatMessage.content}
            </ReactMarkdown>
        </pre> */}
            {/* {chatMessage.content} */}
        <div className="search-params-thread">
          {filteredFlightData.length > 0 && (
            <div>
              <Box sx={{ width: '100%', borderBottom: '1.5px solid gray', margin: '20px 0px 5px 0px' }} />
              <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 0 8px 20px' }}>
                <p style={{ margin: 0, padding: 0 }}>Search Query</p>
                <Box sx={{ marginLeft: '10px' }}>
                  <Button
                    variant="outlined"
                    endIcon={<RefreshIcon sx={{ width: '16px', height: '16px' }} />}
                    onClick={() => runFlightSearch(ai_message_id)}
                    sx={{
                      fontSize: '10.5px',
                      textTransform: 'none',
                      padding: '1px 5px',
                      minWidth: 'fit-content',
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                      '&:hover': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    }}
                  >
                    New Search
                    {/* Refresh Search */}
                  </Button>
                </Box>
                <Box>
                  <PaginationComponent totalPages={N} idx={idx} setIdx={setIdx} />
                </Box>
              </Box>
            </div>
          )}
          {filteredFlightData.length > 0 && (
            <div className="search-params-component">
                {threadFlightData.length > 0 && matchingNewFlightParams && (
                    <FlightSearchBar 
                        flightParams={paginatedParamsData[idx]} 
                        newFlightParams={paginatedNewParamsData[idx]} 
                        setNewFlightParamsArr={setNewFlightParamsArr} 
                    />
                )}
              {/* <FlightSearchComponent chatMessage={chatMessage} /> */}
            </div>
          )}
        </div>
        <div>
          {filteredFlightData.length > 0 && (
            <div>
              <Box sx={{ width: '100%', borderBottom: '1px solid gray', margin: '13px 0px 5px 0px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height:'100%', padding: '0 20px 2px 20px' }}>
                <p style={{ 'padding': '0 0 0px 20px',  }}>Search Results</p>
                <SortButton 
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                />
              </div>
            </div>
          )}
        </div>
        <div className="message-flight-table">
          {filteredFlightData.length > 0 && (
            <FlightsTable
              flightDataArr={paginatedFilteredFlightData[idx]}
              threadFlightData={threadFlightData}
              setThreadFlightData={setThreadFlightData}
              setFavoriteFlightData={setFavoriteFlightData}
              sortBy={sortBy}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AIMessage;
