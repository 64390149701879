import React, { useState } from 'react';
import { Box, TextField, Popover, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)(({ theme, hasChanged }) => ({
  '& .MuiInputBase-input': {
    paddingRight: '0px !important', // Ensure the padding-right is set to 0
    fontSize: '12px', // Change this value to adjust the text size
  },
  '& .MuiInputAdornment-root': {
    marginRight: '-14px', // Adjust this value to remove the unwanted padding
  },
  width: 'fit-content',
  ...getStyleForChangedParams(hasChanged),
  // width: 'auto',
}));

const getStyleForChangedParams = (hasChanged) => {
  // const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
  return {
      // '& .MuiInputBase-input': {
      //     // color: hasChanged ? '#f4777a' : theme.palette.text.secondary, // Changes text color
      //     // color: hasChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Changes text color
      // },
      '& .MuiInputLabel-root': {
          color: hasChanged ? '#017BFE' : 'defaultColor', // Changes label text color
          // color: hasChanged ? '#f4777a' : 'defaultColor', // Changes label text color
          // color: hasChanged ? theme.palette.text.primary : 'defaultColor', // Changes label text color
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Changes border colorf76a6c
              borderWidth: hasChanged ? '1.5px' : '1px'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor' // Remove hover effect for border
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Ensure focused state matches
          },
          '&:hover fieldset': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor' // Ensure hover state matches
          }
      }
  };
};



export const DateRangeButton = ({ 
  flightParams,
  newFlightParams, 
  updateNewFlightParamsArr,
  filterNames,                   // = 'tripType',
  filterDisplayName,            // = 'Trip Type',
  valueDefault,                 // = newFlightParams.params[filterName] || 'M';,
  mapValueToDisplay,            // = {'M':'Economy','C':'Business','F':'First'},
  nullValue,
  widthOverride
}) => {
  
    console.log('DatesButton.newFlightParams', newFlightParams)
    const [anchorEl, setAnchorEl] = useState(null);
    const [dates, setDates] = useState({
        outbound_date_from: newFlightParams.params.outbound_date_from ? moment(newFlightParams.params.outbound_date_from, "DD/MM/YYYY") : null,
        outbound_date_to: newFlightParams.params.outbound_date_to ? moment(newFlightParams.params.outbound_date_to, "DD/MM/YYYY") : null,
        // return_date_from: newFlightParams.params.return_date_from ? moment(newFlightParams.params.return_date_from, "DD/MM/YYYY") : null,
        // return_date_to: newFlightParams.params.return_date_to ? moment(newFlightParams.params.return_date_to, "DD/MM/YYYY") : null,
    }); 
    
    // const keys = ['outbound_date_from', 'outbound_date_to'];
    // const hasChanged = haveValuesChanged(keys, flightParams.params, newFlightParams.params);
    const hasChanged = filterNames.some(key => flightParams.params[key] !== newFlightParams.params[key]);
    console.log('DateRangeButton.hasChanged', hasChanged)
    console.log('DateRangeButton.dates', dates)

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleDatePickerClick = (event) => {
        event.stopPropagation();
    };
    

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = ( key ) => ( newValue ) => {
        console.log('handleDateChange', key, newValue);
        updateNewFlightParamsArr(key, newValue.format('DD/MM/YYYY'));
    };



  const open = Boolean(anchorEl);
  const id = open ? 'dates-popover' : undefined;

  const formatDateRange = (start, end) => {
    if (!start || !end) return 'Select dates';
    const formattedStart = moment(start).format('ddd MMM D');
    const formattedEnd = moment(end).format('ddd MMM D');
    return `${formattedStart} - ${formattedEnd}`;
  };


  return (
    <Box>
      <CustomTextField
        label="Outbound"
        value={formatDateRange(dates.outbound_date_from, dates.outbound_date_to)}
        onClick={handleClick}
        hasChanged={hasChanged}
        fullWidth
        InputProps={{
            readOnly: true,
            sx: {
                '& .MuiInputBase-input': {
                    fontSize: '12px', // Sets the text size of the input
                }
            }
        }}
        InputLabelProps={{
            sx: {
                fontSize: '12px', // Optionally sets the label text size if needed
            }
        }}
        sx={{ fontSize: '12px' }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
      <Box sx={{ p: 2 }} className="popover-content" onClick={handleDatePickerClick}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Outbound Date From"
              value={dates.outbound_date_from}
              onChange={handleDateChange('outbound_date_from')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      <Box sx={{ p: 2 }} className="popover-content" onClick={handleDatePickerClick}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Outbound Date To"
              value={dates.outbound_date_to}
              onChange={handleDateChange('outbound_date_to')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </Box>
  );
};

// export default DatesButton;




export const ReturnDatesButton = ({ newFlightParams, updateNewFlightParamsArr }) => {
    const directionStr = 'Return';
    console.log('DatesButton.newFlightParams', newFlightParams)
    const [anchorEl, setAnchorEl] = useState(null);
    const [dates, setDates] = useState({
        return_date_from: newFlightParams.params.return_date_from ? moment(newFlightParams.params.return_date_from, "DD/MM/YYYY") : null,
        return_date_to: newFlightParams.params.return_date_to ? moment(newFlightParams.params.return_date_to, "DD/MM/YYYY") : null,
    });
    console.log('DatesButton.dates', dates)

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleDatePickerClick = (event) => {
        event.stopPropagation();
    };
    

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = ( key ) => ( newValue ) => {
        console.log('handleDateChange', key, newValue);
        updateNewFlightParamsArr(key, newValue.format('DD/MM/YYYY'));
    };



  const open = Boolean(anchorEl);
  const id = open ? 'dates-popover' : undefined;

  const formatDateRange = (start, end) => {
    if (!start || !end) return 'Select dates';
    const formattedStart = moment(start).format('ddd MMM D');
    const formattedEnd = moment(end).format('ddd MMM D');
    return `${formattedStart} - ${formattedEnd}`;
  };


  return (
    <Box>
      <TextField
        label="Return"
        value={formatDateRange(dates.return_date_from, dates.return_date_to)}
        onClick={handleClick}
        fullWidth
        InputProps={{
            readOnly: true,
            sx: {
                '& .MuiInputBase-input': {
                    fontSize: '12px', // Sets the text size of the input
                }
            }
        }}
        InputLabelProps={{
            sx: {
                fontSize: '12px', // Optionally sets the label text size if needed
            }
        }}
        sx={{ fontSize: '12px' }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
      <Box sx={{ p: 2 }} className="popover-content" onClick={handleDatePickerClick}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Return Date From"
              value={dates.return_date_from}
              onChange={handleDateChange('return_date_from')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      <Box sx={{ p: 2 }} className="popover-content" onClick={handleDatePickerClick}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Return Date To"
              value={dates.return_date_to}
              onChange={handleDateChange('return_date_to')}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </Box>
  );
};


// import React, { useState } from 'react';
// import { Box, TextField, Popover, Button } from '@mui/material';
// import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers';
// // import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import moment from 'moment';

// const DatesButton = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [value, setValue] = useState([null, null]);

//   const handleClick = (event) => {
//     event.stopPropagation();
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'dates-popover' : undefined;

//   const formatDateRange = (range) => {
//     if (!range[0] || !range[1]) return 'Select dates';
//     const start = moment(range[0]).format('ddd MMM D');
//     const end = moment(range[1]).format('ddd MMM D');
//     return `${start} - ${end}`;
//   };

//   return (
//     <Box>
//       <TextField
//         label="Departure"
//         value={formatDateRange(value)}
//         onClick={handleClick}
//         fullWidth
//         InputProps={{
//           readOnly: true,
//         }}
//       />
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'left',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'left',
//         }}
//       >
//         <Box sx={{ p: 2 }} className="popover-content">
//           <LocalizationProvider dateAdapter={AdapterMoment}>
//             <DateRangePicker
//               startText="Start"
//               endText="End"
//               value={value}
//               onChange={(newValue) => {
//                 setValue(newValue);
//               }}
//               renderInput={(startProps, endProps) => (
//                 <div className="date-range-picker">
//                   <TextField {...startProps} />
//                   <Box sx={{ mx: 2 }}> to </Box>
//                   <TextField {...endProps} />
//                 </div>
//               )}
//             />
//             <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
//               Done
//             </Button>
//           </LocalizationProvider>
//         </Box>
//       </Popover>
//     </Box>
//   );
// };

// export default DatesButton;





// import React, { useState } from 'react';
// import { Box, TextField, Popover, Typography, Button } from '@mui/material';
// import { DateRangePicker, LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import moment from 'moment';

// const DatesButton = () => {
// const [anchorEl, setAnchorEl] = useState(null);
// const [value, setValue] = useState([null, null]);

// const handleClick = (event) => {
// event.stopPropagation();
//     setAnchorEl(event.currentTarget);
// };

// const handleClose = () => {
//     setAnchorEl(null);
// };

// const open = Boolean(anchorEl);
// const id = open ? 'dates-popover' : undefined;

// const formatDateRange = (range) => {
//     if (!range[0] || !range[1]) return 'Select dates';
//     const start = moment(range[0]).format('ddd MMM D');
//     const end = moment(range[1]).format('ddd MMM D');
//     return `${start} - ${end}`;
// };

// return (
//     <Box>
//     <TextField
//         label="Departure"
//         value={formatDateRange(value)}
//         onClick={handleClick}
//         fullWidth
//         InputProps={{
//         readOnly: true,
//         }}
//     />
//     <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//         vertical: 'bottom',
//         horizontal: 'left',
//         }}
//         transformOrigin={{
//         vertical: 'top',
//         horizontal: 'left',
//         }}
//     >
//         <Box sx={{ p: 2 }} className="popover-content">
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <DateRangePicker
//             startText="Start"
//             endText="End"
//             value={value}
//             onChange={(newValue) => {
//                 setValue(newValue);
//             }}
//             renderInput={(startProps, endProps) => (
//                 <div className="date-range-picker">
//                 <TextField {...startProps} />
//                 <Box sx={{ mx: 2 }}> to </Box>
//                 <TextField {...endProps} />
//                 </div>
//             )}
//             />
//             <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
//             Done
//             </Button>
//         </LocalizationProvider>
//         </Box>
//     </Popover>
//     </Box>
// );
// };

// export default DatesButton;
  