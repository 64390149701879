import React, { useState } from 'react';
import { Box, Button, IconButton, InputAdornment, Popover, Slider, TextField, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './TimeFilter.css';


const CustomTextField = styled(TextField)(({ theme, paramKey, hasChanged }) => ({
  '& .MuiInputBase-input': {
    paddingRight: '0px !important', // Ensure the padding-right is set to 0
    fontSize: '12px', // Change this value to adjust the text size
  },
  '& .MuiInputAdornment-root': {
    marginRight: '-14px', // Adjust this value to remove the unwanted padding
  },
  width: 'fit-content',
  ...getStyleForChangedParams(paramKey, hasChanged),
  // width: 'auto',
}));

// const getStyleForChangedParams = (key, hasChanged ) => {
//   // const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
//   return {
//     color: hasChanged ? 'blue' : 'inherit', // Changes text color
//     '& fieldset': {
//       borderColor: hasChanged ? 'blue' : 'inherit' // Changes border color
//     }
//   };
// };
const getStyleForChangedParams = (key, hasChanged) => {
  // const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
  return {
      // '& .MuiInputBase-input': {
      //     // color: hasChanged ? '#f4777a' : theme.palette.text.secondary, // Changes text color
      //     // color: hasChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Changes text color
      // },
      '& .MuiInputLabel-root': {
          color: hasChanged ? '#017BFE' : 'defaultColor', // Changes label text color
          // color: hasChanged ? '#f4777a' : 'defaultColor', // Changes label text color
          // color: hasChanged ? theme.palette.text.primary : 'defaultColor', // Changes label text color
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Changes border colorf76a6c
              borderWidth: hasChanged ? '1.5px' : '1px,'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor' // Remove hover effect for border
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Ensure focused state matches
          },
          '&:hover fieldset': {
              borderColor: hasChanged ? '#4ea2fc' : 'defaultColor' // Ensure hover state matches
          }
      }
  };
};


export const TimeFilterOld = ({ flightParams, newFlightParams, updateNewFlightParamsArr }) => {
  console.log('TimeFilter.flightParams', flightParams);
  console.log('TimeFilter.newFlightParams', newFlightParams);
    const [timesAnchorEl, setTimesAnchorEl] = useState(null);
    const [times, setTimes] = useState({
        outbound_depart_time_earliest: newFlightParams.params.outbound_depart_time_earliest || 0,
        outbound_depart_time_latest: newFlightParams.params.outbound_depart_time_latest || 24,
        outbound_arrive_time_earliest: newFlightParams.params.outbound_arrive_time_earliest || 0,
        outbound_arrive_time_latest: newFlightParams.params.outbound_arrive_time_latest || 24,
        return_depart_time_earliest: newFlightParams.params.return_depart_time_earliest || 0,
        return_depart_time_latest: newFlightParams.params.return_depart_time_latest || 24,
        return_arrive_time_earliest: newFlightParams.params.return_arrive_time_earliest || 0,
        return_arrive_time_latest: newFlightParams.params.return_arrive_time_latest || 24
    });
    // const hasChanged = flightParams.params[filterName] !== newFlightParams.params[filterName];
    const keys = ['outbound_depart_time_earliest', 'outbound_depart_time_latest', 'outbound_arrive_time_earliest', 'outbound_arrive_time_latest', 'return_depart_time_earliest', 'return_depart_time_latest', 'return_arrive_time_earliest', 'return_arrive_time_latest'];
    // const hasChanged = haveValuesChanged(keys, flightParams.params, newFlightParams.params);
    const hasChanged = keys.some(key => flightParams.params[key] !== newFlightParams.params[key]);
    console.log('TimeFilterOld.hasChanged', hasChanged)
    const widthStr = '80px'
  
    const handleTimesClick = (event) => {
      event.stopPropagation();
      setTimesAnchorEl(event.currentTarget);
    };
  
    const handleTimesClose = () => {
      setTimesAnchorEl(null);
    };
  
    const handleSliderChange_ = (key) => (event, newValue) => {
        console.log('handleSliderChange', key, newValue)
        
        setTimes((prevTimes) => ({
            ...prevTimes,
            [key[0]]: newValue[0],
            [key[1]]: newValue[1],
        }));
        console.log('handleSliderChange.times', times)
    };

    const handleSliderChangeCommitted_ = (key) => (event, newValue) => {
        console.log('handleSliderChangeCommitted', key, newValue);
        updateNewFlightParamsArr(key[0], newValue[0]);
        updateNewFlightParamsArr(key[1], newValue[1]);
    };


  
    console.log('times', times);

    const timesOpen = Boolean(timesAnchorEl);
  
    return (
      <Box sx={{ 
        display: 'inline-flex !important', 
          gap: 2, 
          alignItems: 'center', 
          width:widthStr 
      }}>
        <CustomTextField
            label="Times"
            value={times.outbound_depart_time_earliest}
            onClick={handleTimesClick}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                          <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{
                sx: {
                    fontSize: '12px', // Optionally sets the label text size if needed
                }
            }}
            ßparamKey="outbound_depart_time_earliest"
        /> 
        <Popover
          open={timesOpen}
          anchorEl={timesAnchorEl}
          onClose={handleTimesClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: 2, width: 300 }} className="times-box">
            <Typography variant="h6">Times</Typography>
            <Typography>Outbound Departure</Typography>
            <div className="times-slider-container">
                <Slider
                    className="times-slider"
                    value={[times.outbound_depart_time_earliest, times.outbound_depart_time_latest]}
                    onChangeCommitted={handleSliderChangeCommitted_(['outbound_depart_time_earliest','outbound_depart_time_latest'])}
                    onChange={handleSliderChange_(['outbound_depart_time_earliest','outbound_depart_time_latest'])}
                    valueLabelDisplay="auto"
                    min={0}
                    max={23}
                    step={1}
                    marks={[
                    { value: 0, label: '12:00 AM' },
                    // { value: 6, label: '6:00 AM' },
                    { value: 12, label: '12:00 PM' },
                    // { value: 18, label: '6:00 PM' },
                    { value: 23, label: '11:59 PM' }
                    ]}
                />
            </div>
            <Typography>Outbound Arrival</Typography>
            <div className="times-slider-container">
                <Slider
                    value={[times.outbound_arrive_time_earliest, times.outbound_arrive_time_latest]}
                    onChangeCommitted={handleSliderChangeCommitted_(['outbound_arrive_time_earliest','outbound_arrive_time_latest'])}
                    onChange={handleSliderChange_(['outbound_arrive_time_earliest','outbound_arrive_time_latest'])}
                    valueLabelDisplay="auto"
                    min={0}
                    max={23}
                    step={1}
                    marks={[
                    { value: 0, label: '12:00 AM' },
                    // { value: 6, label: '6:00 AM' },
                    { value: 12, label: '12:00 PM' },
                    // { value: 18, label: '6:00 PM' },
                    { value: 23, label: '11:59 PM' }
                    ]}
                />
            </div>
            <Typography>Return Departure</Typography>
            <div className="times-slider-container">
                <Slider
                    value={[times.return_depart_time_earliest, times.return_depart_time_latest]}
                    onChangeCommitted={handleSliderChangeCommitted_(['return_depart_time_earliest','return_depart_time_latest'])}
                    onChange={handleSliderChange_(['return_depart_time_earliest','return_depart_time_latest'])}
                    valueLabelDisplay="auto"
                    min={0}
                    max={23}
                    step={1}
                    marks={[
                    { value: 0, label: '12:00 AM' },
                    // { value: 6, label: '6:00 AM' },
                    { value: 12, label: '12:00 PM' },
                    // { value: 18, label: '6:00 PM' },
                    { value: 23, label: '11:59 PM' }
                    ]}
                />
            </div>
            <Typography>Return Arrival</Typography>
            <div className="times-slider-container">
                <Slider
                    value={[times.return_arrive_time_earliest, times.return_arrive_time_latest]}
                    onChangeCommitted={handleSliderChangeCommitted_(['return_arrive_time_earliest','return_arrive_time_latest'])}
                    onChange={handleSliderChange_(['return_arrive_time_earliest','return_arrive_time_latest'])}
                    valueLabelDisplay="auto"
                    min={0}
                    max={23}
                    step={1}
                    marks={[
                    { value: 0, label: '12:00 AM' },
                    // { value: 6, label: '6:00 AM' },
                    { value: 12, label: '12:00 PM' },
                    // { value: 18, label: '6:00 PM' },
                    { value: 23, label: '11:59 PM' }
                    ]}
                />
            </div>
          </Box>
        </Popover>
      </Box>
    );
  };