import React, { useEffect, useState } from 'react';
import { Box, TextField, Popover, Button } from '@mui/material';

import { styled } from '@mui/system';


const debouncedFunction = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

// const CustomTextField = styled(TextField)(({ theme }) => ({
//     '& .MuiInputBase-input': {
//         // paddingRight: '0px !important', // Ensure the padding-right is set to 0
//         fontSize: '12px', // Change this value to adjust the text size
//     },
//     '& .MuiInputAdornment-root': {
//         marginRight: '-14px', // Adjust this value to remove the unwanted padding
//     },
//     width: 'fit-content',
//     // width: 'auto',
// }));
const CustomTextField = styled(TextField)(({ theme, paramKey, hasChanged }) => ({
    '& .MuiInputBase-input': {
      paddingRight: '0px !important', // Ensure the padding-right is set to 0
      fontSize: '12px', // Change this value to adjust the text size
    },
    '& .MuiInputAdornment-root': {
      marginRight: '-14px', // Adjust this value to remove the unwanted padding
    },
    width: 'fit-content',
    ...getStyleForChangedParams(paramKey, hasChanged),
    // width: 'auto',
}));
const getStyleForChangedParams = (key, hasChanged) => {
    // const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
    console.log('hasChanged1', hasChanged)
    return {
        // '& .MuiInputBase-input': {
        //     // color: hasChanged ? '#f4777a' : theme.palette.text.secondary, // Changes text color
        //     // color: hasChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Changes text color
        // },
        '& .MuiInputLabel-root': {
            color: hasChanged ? '#017BFE' : 'defaultColor', // Changes label text color
            // color: hasChanged ? '#f4777a' : 'defaultColor', // Changes label text color
            // color: hasChanged ? theme.palette.text.primary : 'defaultColor', // Changes label text color
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Changes border colorf76a6c
                borderWidth: hasChanged ? '1.5px' : '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Remove hover effect for border
                borderWidth: '1px',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Ensure focused state matches
                borderWidth: '1px',
            },
            '&:hover fieldset': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Ensure hover state matches
                borderWidth:  '1px',
            }
        }
    };
};



export const OriginDestinationFilter = ({ 
    locType, 
    flightParams,
    newFlightParams, 
    updateNewFlightParamsArr 
}) => {
    const locTypeCap = locType.charAt(0).toUpperCase() + locType.slice(1).toLowerCase();
    console.log('DatesButton.newFlightParams', newFlightParams)
    const [anchorEl, setAnchorEl] = useState(null);
    const [location, setLocation] = useState(newFlightParams.params[locType] || null);
    const hasChanged = flightParams.params[locType] !== newFlightParams.params[locType];

    console.log('OriginDestinationFilter.location', location)

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };


    useEffect(() => {
        setLocation(newFlightParams.params[locType] || '');
    }, [newFlightParams.params[locType]]);

    // const debouncedupdateNewFlightParamsArr = debouncedFunction(updateNewFlightParamsArr, 500);

    // const handleLocationChange = (e) => {
    //     const newValue = e.target.value;
    //     setLocation(newValue);
    //     debouncedupdateNewFlightParamsArr(locType, newValue);
    // };
    const handleLocationChange = (e) => {
        const newValue = e.target.value;
        setLocation(newValue);
    };

    const handleBlur = () => {
        updateNewFlightParamsArr(locType, location);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'dates-popover' : undefined;


    return (
        <Box>
            <CustomTextField
                sx = {{ fontSize: '12px', width:'120px'}}
                fullWidth
                label={locTypeCap}
                value={location}
                onClick={handleClick}
                onChange={handleLocationChange}
                onBlur={handleBlur}
                paramKey={locType}
                hasChanged={hasChanged}
                InputProps={{
                    sx: {
                        '& .MuiInputBase-input': {
                            fontSize: '12px', // Sets the text size of the input
                        }
                    }
                }}
                InputLabelProps={{
                    sx: {
                        fontSize: '12px', // Optionally sets the label text size if needed
                    }
                }}
                // onChange={(e) => setOrigin(e.target.value)}
                // onChange={(e) => updateNewFlightParamsArr( locType, e.target.value )}
            />

        </Box>
    );
};



            // <Popover
            //     id={id}
            //     open={open}
            //     anchorEl={anchorEl}
            //     onClose={handleClose}
            //     anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'left',
            //     }}
            //     transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //     }}
            //     disableRestoreFocus
            // >
            //     <Box sx={{ p: 2 }} className="popover-content" onClick={handleDatePickerClick}>
            //         <LocalizationProvider dateAdapter={AdapterMoment}>
            //             <DatePicker
            //                 label="Outbound Date From"
            //                 value={dates.outbound_date_from}
            //                 onChange={handleDateChange('outbound_date_from')}
            //                 renderInput={(params) => <TextField {...params} />}
            //             />
            //         </LocalizationProvider>
            //     </Box>
            //     <Box sx={{ p: 2 }} className="popover-content" onClick={handleDatePickerClick}>
            //         <LocalizationProvider dateAdapter={AdapterMoment}>
            //             <DatePicker
            //                 label="Outbound Date To"
            //                 value={dates.outbound_date_to}
            //                 onChange={handleDateChange('outbound_date_to')}
            //                 renderInput={(params) => <TextField {...params} />}
            //             />
            //         </LocalizationProvider>
            //     </Box>
            // </Popover>