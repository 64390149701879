import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PaginationComponent = ({ totalPages, idx, setIdx }) => {
    console.log('PaginationComponent', totalPages, idx, setIdx);
    console.log('totalPages', totalPages);
    console.log('idx', idx);
    // const [currentPage, setCurrentPage] = useState(1);

    const handlePrevious = () => {
        if (idx > 0) {
            // setCurrentPage(currentPage - 1);
            setIdx(idx - 1);
        }
    };

    const handleNext = () => {
        if (idx < totalPages) {
            // setCurrentPage(currentPage + 1);
            setIdx(idx + 1);
        }
    };

    return (
        <Box 
            display="flex" 
            alignItems="center"
            sx={{paddingLeft: '5px'}}
        >
            <IconButton
                onClick={handlePrevious}
                disabled={idx === 0}
                sx={{ fontSize: '12px', padding:'0px 1px 0px 10px' }} // Adjust the arrow size
            >
                <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            <Typography 
                variant="body1"
                sx={{ fontSize: '12px', mx: 2, margin:'0px 2px 0px 2px' }}
            >
                {idx+1} / {totalPages}
            </Typography>
            <IconButton 
                onClick={handleNext} 
                disabled={idx === totalPages-1}
                sx={{ fontSize: '12px', padding:'0px 10px 0px 1px' }}
            >
                <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
        </Box>
    );
};

export default PaginationComponent;
