import React from 'react';

const HumanMessage = ({ chatMessage }) => {
  return (
    <div className="chat-message">
      <p className="role">{chatMessage.role}</p>
      <div className="content">
        <pre>{chatMessage.content}</pre>
      </div>
    </div>
  );
};

export default HumanMessage;
