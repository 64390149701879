import React, { useMemo, useState } from 'react';
import AIMessage from '../FeedMessage/AIMessage';
import HumanMessage from '../FeedMessage/HumanMessage';
// import TravelGPTPage from '../../pages/TravelGPTPage/TravelGPTPage';
import '../../pages/TravelGPTPage/TravelGPTPage.css'; 

const ThreadMessageFeed = ({
  currentTitle,
  currentThreadId,
  threads,
  threadFlightData,
  setThreadFlightData,
  flightSearchParamsArr,
  newFlightParamsArr,
  setNewFlightParamsArr,
  runFlightSearch,
  setFavoriteFlightData,
}) => {
  console.log('ThreadMessageFeed.threadFlightData', threadFlightData);
  console.log('ThreadMessageFeed.flightSearchParamsArr', flightSearchParamsArr);



  // const FlightSearchComponent = ({ chatMessage }) => {
  //   console.log('FlightSearchComponent.newFlightParamsArr', newFlightParamsArr);

  //   const matchingNewFlightParams = useMemo(() => {
  //     return newFlightParamsArr.find(param => param.ai_message_id === chatMessage.id);
  //   }, [newFlightParamsArr, chatMessage.id]);

  //   const matchingFlightParams = useMemo(() => {
  //     return flightSearchParamsArr.find(param => param.ai_message_id === chatMessage.id);
  //   }, [flightSearchParamsArr, chatMessage.id]); 

  //   return (
  //     <>
  //       {threadFlightData.length > 0 && matchingNewFlightParams && chatMessage.role === 'ai' && matchingNewFlightParams && (
  //         <FlightSearchBar flightParams={matchingFlightParams} newFlightParams={matchingNewFlightParams} setNewFlightParamsArr={setNewFlightParamsArr} />
  //       )}
  //     </>
  //   );
  // };

  const currentChat = useMemo(() => {
    return threads.find(chat => chat.thread_id === currentThreadId);
  }, [threads, currentThreadId]);

  const chatHistory = useMemo(() => {
    return currentChat?.history || [];
  }, [currentChat]);

  return (
    // <div className="travel-gpt-main-chat-history">
    <div>
      <ul className="travelgpt-feed">
        {chatHistory.map((chatMessage, index) => {
          if (chatMessage.role === 'ai') {
            return (
              <li key={index} className='travelgpt-feed-li'>
                <AIMessage
                  chatMessage={chatMessage}
                  threadFlightData={threadFlightData}
                  flightSearchParamsArr={flightSearchParamsArr}
                  newFlightParamsArr={newFlightParamsArr}
                  setNewFlightParamsArr={setNewFlightParamsArr}
                  runFlightSearch={runFlightSearch}
                  setThreadFlightData={setThreadFlightData}
                  setFavoriteFlightData={setFavoriteFlightData}
                />
              </li>
            );
          } else {
            return (
              <li key={index} className='travelgpt-feed-li'>
                <HumanMessage chatMessage={chatMessage} />
              </li>
            );
          }
        })}
        {/* {chatHistory.map((chatMessage, index) => {
          const filteredFlightData = threadFlightData.filter(item => item.ai_message_id === chatMessage.id);
          const groupedBySearchId = filteredFlightData.reduce((acc, item) => {
            const key = item.search_id;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {});
          const listofParams = Object.values(groupedBySearchId);
          console.log('listofParams', listofParams);
          console.log('listofParams.length', listofParams.length);

          const ai_message_id = filteredFlightData.length > 0 ? filteredFlightData[0]['ai_message_id'] : null;
          const N = listofParams.length;
          console.log('N', N);

          return (
            <li key={index}>
              <div className="chat-message">
                <p className="role">{chatMessage.role}</p>
                <div className="content">
                  <pre>{chatMessage.content}</pre>
                  <div className="search-params-thread">
                    {filteredFlightData.length > 0 && flightSearchParamsArr.length > 0 && chatMessage.role === 'ai' && (
                      <div>
                        <Box sx={{ width: '100%', borderBottom: '1.5px solid gray', margin: '20px 0px 5px 0px' }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 0 8px 20px' }}>
                          <p style={{ margin: 0, padding: 0 }}>Search Query</p>
                          <Box sx={{ marginLeft: '10px' }}>
                            <Button
                              variant="outlined"
                              endIcon={<RefreshIcon sx={{ width: '16px', height: '16px' }} />}
                              onClick={() => runFlightSearch(ai_message_id)}
                              sx={{
                                fontSize: '10.5px',
                                textTransform: 'none',
                                padding: '1px 5px',
                                minWidth: 'fit-content',
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                '&:hover': {
                                  borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                              }}
                            >
                              Refresh Search
                            </Button>
                          </Box>
                          <Box>
                            <PaginationComponent 
                              totalPages={N} 
                              idx={idx} 
                              setIdx={setIdx} 
                            />
                          </Box>
                        </Box>
                      </div>
                    )}
                    {filteredFlightData.length > 0 && flightSearchParamsArr.length > 0 && chatMessage.role === 'ai' && (
                      <div className="search-params-component">
                        <FlightSearchComponent chatMessage={chatMessage} />
                      </div>
                    )}
                  </div>
                  <div>
                    {filteredFlightData.length > 0 && flightSearchParamsArr.length > 0 && chatMessage.role === 'ai' && (
                      <div>
                        <Box sx={{ width: '100%', borderBottom: '1px solid gray', margin: '13px 0px 5px 0px' }} />
                        <p style={{ 'padding': '0 0 8px 20px' }}>Search Results</p>
                      </div>
                    )}
                  </div>
                  <div className="message-flight-table">
                    {filteredFlightData.length > 0 && chatMessage.role === 'ai' && (
                      <MemoizedFlightsTable
                        flightDataArr={filteredFlightData}
                        threadFlightData={threadFlightData}
                        setThreadFlightData={setThreadFlightData}
                        setFavoriteFlightData={setFavoriteFlightData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </li>
          );
        })} */}
      </ul>
    </div>
  );
};

export default ThreadMessageFeed;
