import React, { useState, useEffect, memo } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
// import { Popover, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
// import SearchIcon from '@mui/icons-material/Search';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { TimeFilter, TimeFilterOld } from './ThreadSearchFilters/TimeFilter';
// import { Slider } from '@mui/material';
// import ClearIcon from '@mui/icons-material/Clear';

import { DateRangeButton, ReturnDatesButton } from './ThreadSearchFilters/DateFilter';
import { OriginDestinationFilter } from './ThreadSearchFilters/OriginDestinationFilter';
import { DropdownFilter } from './ThreadSearchFilters/DropdownFilter';
import { TimeFilterOld } from './ThreadSearchFilters/TimeFilter';
// import { RefreshButton } from './ThreadSearchFilters/RefreshButton';

const FlightSearchBar = ({ flightParams, newFlightParams, setNewFlightParamsArr }) => {
  
  console.log('FlightSearchBar.flightParams', flightParams);
  console.log('FlightSearchBar.newFlightParams', newFlightParams);
  const aiMessageId = newFlightParams.ai_message_id;


  useEffect(() => {
    console.log('newFlightParams updated:', newFlightParams);
  }, [newFlightParams]);





  const updateNewFlightParamsArr = async (paramKey, paramValue) => {
    console.log('updateNewFlightParamsArr', paramKey, paramValue);
    setNewFlightParamsArr((prevnewFlightParamsArr) => {
      return prevnewFlightParamsArr.map((item) => {
        if (item.ai_message_id === aiMessageId) {
          const { datecreated, dateupdated, ...rest } = item;
          return {
            ...rest,
            params: {
              ...rest.params,
              [paramKey]: paramValue
            }
          };
        }
        return item;
      });
    });
  };


  return (
    <Box sx={{ maxWidth: '720px', alignItems: 'center', margin:'auto' }}>
        <Box sx={{ padding: '7px 0 0 0 !important' }}>
            <Grid container spacing={2} alignItems="center">

                <Grid item sx={{ display: 'inline-flex', width: 'auto' }}>
                    <DropdownFilter
                        flightParams={flightParams}
                        newFlightParams={newFlightParams} 
                        updateNewFlightParamsArr={updateNewFlightParamsArr}
                        filterName = 'tripType'
                        filterDisplayName = 'Trip Type'
                        values = {['one-way','round-trip']}
                        valueDefault = 'one-way'
                        mapValueToDisplay = {{'one-way':'One-Way','round-trip':'Round-Trip'}}
                        widthOverride = '115px'
                    />
                </Grid>

                <Grid item>
                    <DropdownFilter
                        flightParams={flightParams}
                        newFlightParams={newFlightParams} 
                        updateNewFlightParamsArr={updateNewFlightParamsArr}
                        filterName = 'num_adults'
                        filterDisplayName = 'Passengers'
                        values = {['1','2','3','4','5','6','7','8']}
                        valueDefault = '1'
                        mapValueToDisplay = {{'1':'  1','2':'  2','3':'  3','4':'  4','5':'  5','6':'  6','7':'  7','8':'  8'}}
                        widthOverride = '90px'
                    />
                </Grid>

                <Grid item>
                    <DropdownFilter
                        flightParams={flightParams}
                        newFlightParams={newFlightParams} 
                        updateNewFlightParamsArr={updateNewFlightParamsArr}
                        filterName = 'class'
                        filterDisplayName = 'Class'
                        values = {['M','C','F']}
                        valueDefault = 'M'
                        mapValueToDisplay = {{'M':'Economy','C':'Business','F':'First'}}
                        widthOverride = '115px'
                    />
                </Grid> 

                <Grid item>
                    <DropdownFilter
                        flightParams={flightParams}
                        newFlightParams={newFlightParams} 
                        updateNewFlightParamsArr={updateNewFlightParamsArr}
                        filterName = 'num_layovers'
                        filterDisplayName = 'Stops'
                        values = {['any','0','1','2','3']}
                        valueDefault = 'any'
                        mapValueToDisplay = {{'any':'Any','0':'Nonstop','1':'1 stop max','2':'2 stops max','3':'3 stops max'}}
                        nullValue = 'any'
                        widthOverride = '150px'
                    />
                </Grid>

                <Grid item>
                    <TimeFilterOld 
                        flightParams={flightParams}
                        newFlightParams={newFlightParams} 
                        updateNewFlightParamsArr={updateNewFlightParamsArr} 
                    />
                </Grid>

            </Grid>
        </Box>
        

        <Box sx={{ padding: '12px 0 0 0 !important' }}>
            <Grid container spacing={2} alignItems="center">

                <Grid item >
                    <OriginDestinationFilter 
                      locType="origin" 
                      flightParams={flightParams}
                      newFlightParams={newFlightParams} 
                      updateNewFlightParamsArr={updateNewFlightParamsArr} 
                    />
                </Grid>

                <Grid item>
                    <IconButton sx={{padding:'0px !important'}}>
                        <SwapHorizIcon sx={{padding:'0 !important'}}/>
                    </IconButton>
                </Grid>

                <Grid item >
                    <OriginDestinationFilter 
                      locType="destination" 
                      flightParams={flightParams} 
                      newFlightParams={newFlightParams}
                      updateNewFlightParamsArr={updateNewFlightParamsArr} 
                    />
                </Grid>

                <Grid item>
                    <DateRangeButton 
                      flightParams={flightParams} 
                      newFlightParams={newFlightParams} 
                      updateNewFlightParamsArr={updateNewFlightParamsArr} 
                      filterNames={['outbound_date_from','outbound_date_to']}
                      filterDisplayName="Origin"
                    />
                </Grid>

                <Grid item>
                    <ReturnDatesButton 
                      flightParams={flightParams} 
                      newFlightParams={newFlightParams} 
                      updateNewFlightParamsArr={updateNewFlightParamsArr} 
                    />
                </Grid>

            </Grid>
        </Box>


    </Box>
  );
};

export default memo(FlightSearchBar); 



