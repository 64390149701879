import React, { useContext } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OpticalObjectRecognition from './pages/OpticalObjectRecognition';
import ChatGPTPage from './pages/ChatGPTPage';
import Base3GPTPage from './pages/Base3GPTPage';
import TravelGPTPage from './pages/TravelGPTPage/TravelGPTPage';
import TabsTravelGPTPage from './pages/TravelGPTPage/TabsTravelGPTPage';
// import DoodlePage from './pages/DoodlePage';
import AppLocal from './pages/LocalGPTPage/AppLocal';
import { AuthProvider } from './services/providers/AuthContext';
import './App.css';



function App() {

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/optical-object-recognition" element={<OpticalObjectRecognition />} />
            <Route path="/chatgpt" element={<ChatGPTPage />} />
            <Route path="/llama3" element={<ChatGPTPage />} />
            <Route path="/base3gpt" element={<Base3GPTPage />} />
            <Route path="/travelgpt" element={<TravelGPTPage />} />
            <Route path="/tabstravelgpt" element={<TabsTravelGPTPage />} />
            <Route path="/localgpt" element={<AppLocal />} />
            {/* <Route path="/doodle" element={<DoodlePage />} /> */}
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;