import React, { useState } from 'react';
import { Menu, MenuItem, Button, Typography, Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SortButton = ({ sortBy, setSortBy }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortType, setSortType] = useState(sortBy);
  const open = Boolean(anchorEl);

  const sortByDisplay = {
    'score': 'Best flights',
    'price': 'Price',
    'duration_total': 'Duration',
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (type) => {
    setSortBy(type);
    handleClose();
  };

  return (
    <Box>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={{fontSize:'11px', padding:'0px'}}
      >
        Sort by: {sortByDisplay[sortBy]}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSortChange('score')}>Best flights</MenuItem>
        <MenuItem onClick={() => handleSortChange('price')}>Price</MenuItem>
        <MenuItem onClick={() => handleSortChange('duration_total')}>Duration</MenuItem>
        {/* <MenuItem onClick={() => handleSortChange('Departure time')}>Departure time</MenuItem>
        <MenuItem onClick={() => handleSortChange('Arrival time')}>Arrival time</MenuItem> */}
      </Menu>
    </Box>
  );
};

export default SortButton;
