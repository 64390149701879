import React, { useState } from 'react';
import { Box, Button, InputAdornment, Popover, Radio, RadioGroup, List, ListItem, ListItemText, Typography, Slider, TextField, IconButton, FormControlLabel, Select, FormControl, InputLabel, MenuItem, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/system';


const CustomTextField = styled(TextField)(({ theme, paramKey, hasChanged }) => ({
    '& .MuiInputBase-input': {
      paddingRight: '0px !important', // Ensure the padding-right is set to 0
      fontSize: '12px', // Change this value to adjust the text size
    },
    '& .MuiInputAdornment-root': {
      marginRight: '-14px', // Adjust this value to remove the unwanted padding
    },
    width: 'fit-content',
    ...getStyleForChangedParams(paramKey, hasChanged),
    // width: 'auto',
}));

const getStyleForChangedParams = (key, hasChanged) => {
    // const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
    return {
        // '& .MuiInputBase-input': {
        //     // color: hasChanged ? '#f4777a' : theme.palette.text.secondary, // Changes text color
        //     // color: hasChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Changes text color
        // },
        '& .MuiInputLabel-root': {
            color: hasChanged ? '#017BFE' : 'defaultColor', // Changes label text color
            // color: hasChanged ? '#f4777a' : 'defaultColor', // Changes label text color
            // color: hasChanged ? theme.palette.text.primary : 'defaultColor', // Changes label text color
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Changes border colorf76a6c
                borderWidth: hasChanged ? '1.5px' : '1px'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor' // Remove hover effect for border
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Ensure focused state matches
            },
            '&:hover fieldset': {
                borderColor: hasChanged ? '#4ea2fc' : 'defaultColor' // Ensure hover state matches
            }
        }
    };
};


export const DropdownFilter = ({ 
    flightParams,
    newFlightParams, 
    updateNewFlightParamsArr,
    filterName,                   // = 'tripType',
    filterDisplayName,            // = 'Trip Type',
    values,                       // = ['M','C','F'],
    valueDefault,                 // = newFlightParams.params[filterName] || 'M';,
    mapValueToDisplay,            // = {'M':'Economy','C':'Business','F':'First'},
    nullValue,
    widthOverride
}) => {


    // console.log('DropdownFilter.newFlightParams', filterName, newFlightParams);

    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(newFlightParams.params[filterName] || valueDefault);
    const theme = useTheme();
    // const defaultInputColor = theme.palette.text.primary;
    const hasChanged = flightParams.params[filterName] !== newFlightParams.params[filterName];


    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      console.log('Anchor Element:', event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        setValue(value);
        updateNewFlightParamsArr(filterName, value);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'dropdown-popover' : undefined;
    const widthStr = widthOverride ? widthOverride + ' !important' : '140px !important';
    
    // const getStyleForChangedParams = (key) => {
    //     const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
    //     return {
    //       color: hasChanged ? 'blue' : 'inherit', // Changes text color
    //       '& fieldset': {
    //         borderColor: hasChanged ? 'blue' : 'inherit' // Changes border color
    //       }
    //     };
    //   };
    // const getStyleForChangedParams = (key, hasChanged) => {
    //     // const hasChanged = flightParams.params[key] !== newFlightParams.params[key];
    //     return {
    //         // '& .MuiInputBase-input': {
    //         //     // color: hasChanged ? '#f4777a' : theme.palette.text.secondary, // Changes text color
    //         //     // color: hasChanged ? theme.palette.text.primary : theme.palette.text.secondary, // Changes text color
    //         // },
    //         '& .MuiInputLabel-root': {
    //             color: hasChanged ? '#017BFE' : 'defaultColor', // Changes label text color
    //             // color: hasChanged ? '#f4777a' : 'defaultColor', // Changes label text color
    //             // color: hasChanged ? theme.palette.text.primary : 'defaultColor', // Changes label text color
    //         },
    //         '& .MuiOutlinedInput-root': {
    //             '& fieldset': {
    //                 borderColor: hasChanged ? '#4ea2fc' : 'defaultColor', // Changes border colorf76a6c
    //                 // borderColor: hasChanged ? '#f78587' : 'defaultColor', // Changes border colorf76a6c
    //                 // borderColor: hasChanged ? '#f4777a' : 'defaultColor', // Changes border color
    //                 borderWidth: hasChanged ? '1.5px' : '1px,'
    //                 // borderColor: hasChanged ? 'blue' : 'defaultColor', // Changes border color
    //             }
    //         }
    //     };
    // };


    return (
       <Box sx={{ 
            display: 'inline-flex !important', 
            gap: 2, 
            alignItems: 'center', 
            width: widthStr 
        }}>
            <CustomTextField
            // {/* <TextField */}
                label={filterDisplayName}
                value={mapValueToDisplay[value] || value}
                onClick={handleClick}
                hasChanged={hasChanged}
                paramKey={filterName}
                fullWidth
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <ArrowDropDownIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    sx: {
                        fontSize: '12px', // Optionally sets the label text size if needed
                    }
                }}
            /> 
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
            >
                <Box sx={{ p: 0, width:'auto'}} className='dropdown-box'>
                    <List>
                        {values.map((key) => (
                            <ListItem
                                key={key}
                                button
                                selected={value === key}
                                onClick={() => handleSelect(key)}
                                sx={{
                                    '&.Mui-selected': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                }}
                            >
                                <ListItemText 
                                    primary={mapValueToDisplay[key] || key} 
                                    primaryTypographyProps={{ sx: { fontSize: '11px' } }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
      </Box>
    );
}; 